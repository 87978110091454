import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { get } from 'lodash-es';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { merge, ReplaySubject, combineLatest } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { OnlineCourseService } from '@app/services/online-course/online-course.service';
import { OnlineProcessService } from '@app/services/online-process/online-process.service';
import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { AbstractOnlineBaseTask } from '@shared/classes/abstract-online-base-task';
import { BaseProcessPage, INextTaskOptions } from '@shared/components/templates/process-page/process-page.component';
import { ACTION_LABELS } from '@shared/constants/actions.constants';
import { PATH_TO_SECTION } from '@shared/constants/section-path.constants';
import { UCError } from '@shared/models/errors';
import { UCProcess } from '@shared/models/process';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { DocumentService } from '@shared/services/document/document.service';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { FileUploadServiceEvent } from '@shared/services/file-upload/file-upload.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { LoggingService } from '@shared/services/logging/logging.service';
import { IProcessRouteParams } from '@shared/services/resolvers/process-resolver/process-resolver.service';
import { UserService } from '@shared/services/user/user.service';
import { UserActivityService } from '@shared/services/user-activity/user-activity.service';

@Component({
  selector: 'uc-online-process-page',
  templateUrl: './online-process-page.component.html',
  styleUrls: ['./online-process-page.component.scss'],
})
export class OnlineProcessPageComponent extends BaseProcessPage implements OnInit, AfterViewInit, OnDestroy {
  public process: UCProcess;
  public task: Task;
  public stage: Stage;
  public params: IProcessRouteParams;
  public queryParams: unknown;

  public strings = strings.components.template.processPage;
  public onlineProcessPageStrings = strings.components.template.onlineProcessPage;
  public errorMessage = '';
  public isFirstTask = false;
  public taskIsUpdating = false;
  public newProcessName: string;
  public applicationYear: string;
  public stageNumber: number;
  public impersonating = false;
  public paymentStatus = '';
  public firstIncompleteStage: Stage;
  public firstIncompleteTask: Task;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  private identityDocs: FileUploadServiceEvent[];

  public invalidSections: string[] = [];

  // eslint-disable-next-line max-lines-per-function
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private flashMessageService: FlashMessageService,
    private applicationService: ApplicationService,
    private coeService: ChangeOfEnrolmentService,
    private applicantService: ApplicantService,
    private documentService: DocumentService,
    private enrolmentService: EnrolmentService,
    private onlineProcessService: OnlineProcessService,
    loggingService: LoggingService,
    private titleService: Title,
    private userActivityService: UserActivityService,
    private userService: UserService,
    private onlineCourseService: OnlineCourseService,
    private ngxSmartModalService: NgxSmartModalService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get stageComplete() {
    return this.task && this.task.actionLabel === ACTION_LABELS.SAVE_AND_SUBMIT;
  }

  get saveAndExit() {
    const exitLabels = [ACTION_LABELS.SAVE_AND_EXIT_ALWAYS, ACTION_LABELS.SAVE_AND_EXIT];
    return !!exitLabels.find((label) => get(this, 'task.actionLabel') === label);
  }

  get actionLabel() {
    const actionLabel = this.task?.actionLabel;
    const stageNum = this.getTaskAndStageIndex(this.params)?.stageIndex + 1;
    const processCode = this.process?.code;
    const stageCount = this.process?.stages?.length;

    return this.strings.actionLabel(actionLabel, stageNum, processCode, stageCount);
  }

  get startCourseLabel() {
    return this.strings.actionLabel('start_course');
  }

  get continueToUCOnlineDashboardLabel() {
    return this.strings.actionLabel('start_learning');
  }

  get isImpersonating() {
    return this.impersonating;
  }

  get isFirstIncompleteStage() {
    return this.stage.code === get(this, 'firstIncompleteStage.code');
  }

  get disableNavigation() {
    return this.impersonating && !this.isFirstIncompleteStage;
  }

  get firstIncompleteStageNumber() {
    return this.process.stages.findIndex((el) => el.code === get(this, 'firstIncompleteStage.code')) + 1;
  }

  get flashMessage() {
    const { stageIndex } = this.getTaskAndStageIndex(this.params);
    const { isInternational } = this.process;
    return this.strings.flashMessage(stageIndex, this.process.code, isInternational);
  }

  get isVerification() {
    return this.task && this.task.code === 'uconline-verification';
  }

  get isCheckoutStage() {
    return this.stage && this.stage.code === 'checkout';
  }

  get isEnrolmentSuccess() {
    return this.task && this.task.code === 'uconline-enrolment-success';
  }

  get onlineSpecialAction() {
    const onlineSpecialActionNeededTasks = [
      'uconline-checkout',
      'resolve-checkout-session',
      'resolve-enrolment-provisioning',
      'uconline-enrolment-success',
      'uconline-next-steps',
    ];
    return onlineSpecialActionNeededTasks.includes(this.task?.code);
  }

  get courseCode() {
    return this.onlineCourseService.onlineCourseCode;
  }

  get occurrence() {
    return this.onlineCourseService.onlineCourseOccur;
  }

  ngAfterViewInit() {
    if (!this.errorMessage) {
      this.hideErrorBanner();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit() {
    combineLatest(this.route.data, this.route.params, this.route.queryParams)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([data, params, queryParams]) => {
        this.updateDataOnResolverEmit(data, params, queryParams);
      });

    this.resetPageTitleAndErrorBanner();

    this.subscribeToAllSortOfError();

    this.userService.userDetail
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((detail) => (this.impersonating = detail && !!detail.impersonated));

    this.documentService.getDocumentsForCategory('IDENTITY').subscribe((docs) => {
      this.identityDocs = docs;
    });

    if (this.isCheckoutStage) {
      this.route?.fragment.subscribe((fragment) => (this.paymentStatus = fragment));
    }
  }

  private subscribeToAllSortOfError() {
    merge(
      this.applicantService.applicantError,
      this.applicationService.applicationError,
      this.coeService.coeError,
      this.documentService.documentError,
      this.enrolmentService.error,
    )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((error) => {
        this.showTaskErrors(error);
      });
  }

  private resetPageTitleAndErrorBanner() {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.setPageTitle();
        this.hideErrorBanner();
      });
  }

  private showTaskErrors(error) {
    if (error.code.match(/(missing|fourOhFour)/)) {
      return;
    }

    if (error.code.match(/fourTwentyTwo/)) {
      this.setErrorMessageAndUpdateFormValidity(error);
    } else {
      this.errorMessage = strings?.error?.code;
    }
    this.showErrorBanner();
  }

  private setErrorMessageAndUpdateFormValidity(error) {
    this.errorMessage = get(strings, get(error, 'data[0].detail')) || get(error, 'data[0].extra.original_msg');
    if (this.currentTask.updateFormValidity) {
      this.currentTask.updateFormValidity(error);
    } else {
      this.log.error(new Error('Error: task components must extend AbstractBaseTask and implement updateFormValidity'));
    }
  }

  private updateDataOnResolverEmit(data, params, queryParams) {
    this.process = data?.processContext?.process;
    this.applicationYear = data?.processContext?.application?.academicYear?.code;
    this.stage = this.process.getStage(params.stage);
    this.task = this.process.getTask(params.stage, params.task);
    this.stageNumber = this.process.stages.findIndex((st) => st.code === params.stage) + 1;
    this.params = params;
    this.queryParams = queryParams;

    this.firstIncompleteStage = this.process.firstIncompleteStage;
    this.firstIncompleteTask = this.stage.firstIncompleteTask;

    const { taskIndex } = this.getTaskAndStageIndex(this.params);
    this.isFirstTask = taskIndex === this.firstVisibleTaskIndex();
  }

  private setPageTitle() {
    const taskTitle = this.task?.title;
    if (taskTitle) {
      this.titleService.setTitle(`${taskTitle} | Application to Enrol | UC`);
    }
  }

  /**
   * Only provide errors to this event output handler if you want the user to see the banner.
   * This must be called at any and all points at which `this.currentTask.update`
   * completes or returns without calling `this.next.emit()`.
   */
  showTaskError(error?: UCError) {
    this.taskIsUpdating = false;
    if (error) {
      this.errorMessage = strings?.error?.code;
      this.showErrorBanner();
    }
  }

  triggerTaskCheckAndUpdate() {
    if (this.task.code === 'complete-stage' || !this.showValidationWarnings()) {
      this.skipOrUpdateTask();
    }
  }

  private skipOrUpdateTask() {
    if (this.task.code === 'uconline-verification' && this.identityDocs.length === 0) {
      this.skipTask();
      return;
    } else {
      this.triggerTaskUpdate();
    }
  }

  private showValidationWarnings() {
    if (this.currentTask instanceof AbstractOnlineBaseTask) {
      const invalidPathArr = this.currentTask.checkFormValidity();
      this.invalidSections = this.pathToSectionSet(invalidPathArr);
      if (invalidPathArr.length > 0) {
        this.ngxSmartModalService.open('validationWarningModal');
        return true;
      } else {
        return false;
      }
    } else {
      // const error = new Error('Task components must extend AbstractOnlineBaseTask and implement checkFormValidity');
      // this.log.error(error);
      // throw error;
    }
  }

  async triggerTaskUpdate() {
    if (get(this, 'currentTask.stopTaskSubmit')) {
      this.currentTask.update();
      return;
    }

    if (!get(this, 'currentTask.dontTriggerTaskIsUpdating')) {
      this.taskIsUpdating = true;
    }

    await this.userActivityService.addTask(this.params);
    this.updateTaskOrGoToNextTask();
  }

  private updateTaskOrGoToNextTask() {
    if (get(this, 'currentTask.update')) {
      this.currentTask.update();
      this.tempOnlineAction();
    } else {
      this.goToNextTask();
    }
  }

  private tempOnlineAction() {
    // TODO: Temp skip uconline verification at this stage (the logic of verification is not fixed)
    if (this.task.code === 'uconline-verification' || this.paymentStatus === 'success') {
      this.goToNextTask({ autoResolve: true });
    }
  }

  cancelTask() {
    this.ngxSmartModalService.open('cancelProcessWarningModal');
  }

  cancelProcess() {
    window.location.assign(environment.hubSpotCoursesUrl);
    this.ngxSmartModalService.close('cancelProcessWarningModal');
  }

  continueProcess() {
    this.ngxSmartModalService.close('cancelProcessWarningModal');
  }

  skipTask() {
    this.ngxSmartModalService.open('verificationSkipWarningModal');
  }

  previousTask() {
    return this.router.navigate(this.getPreviousUrl(this.params), { queryParams: this.queryParams });
  }

  completeStage() {
    if (this.task.stop) {
      this.goToDashboard(this.flashMessage).then(() => (this.taskIsUpdating = false));
    } else {
      this.router
        .navigate(this.getNextUrl(this.params), { queryParams: this.queryParams })
        .then(() => (this.taskIsUpdating = false));
    }
  }

  goToNextTask(options: INextTaskOptions = { autoResolve: false }) {
    this.hideErrorBanner();
    if (this.stageComplete) {
      return this.completeStage();
    }

    this.actionForNotCompleteStage(options);
  }

  private actionForNotCompleteStage(options: INextTaskOptions) {
    if (this.saveAndExit && !options.autoResolve) {
      return this.goToDashboard(this.flashMessage).then(() => (this.taskIsUpdating = false));
    }

    this.evaluateProcess();
  }

  private evaluateProcess() {
    this.onlineProcessService
      .evaluateProcess(this.process.code, this.params.year)
      .pipe(filter((process) => !!process))
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe({
        next: (process) => {
          this.process = process;
          this.stage = process.getStage(this.params.stage);
          this.task = process.getTask(this.params.stage, this.params.task);

          if (!this.stage || !this.task) {
            return this.goToDashboard();
          }

          return this.router.navigate(this.getNextUrl(this.params), { queryParams: this.queryParams });
        },
        complete: () => {
          this.taskIsUpdating = false;
        },
      });
  }

  public goToDashboard(message?: string): Promise<boolean> {
    if (message) {
      this.flashMessageService.pushSuccess(message, { countdown: 10 });
    }
    return this.router.navigate(internalUrls.dashboard, { queryParams: this.queryParams });
  }

  private getPreviousUrl(params: IProcessRouteParams) {
    return this.getNextUrl(params, false);
  }

  private getNextUrl(params: IProcessRouteParams, forward = true) {
    const { taskIndex, stageIndex } = this.getTaskAndStageIndex(params);
    const nextStageCode = get(this, `process.stages[${forward ? stageIndex + 1 : stageIndex - 1}].code`);
    const nextTaskCode = get(this, `stage.tasks[${forward ? taskIndex + 1 : taskIndex - 1}].code`);

    // If there is a nextTaskCode, it means we aren't done the current stage yet, so ignore nextStageCode
    let urlParts = internalUrls.dashboard;
    urlParts = this.getNextTaskUrlParts(nextTaskCode, nextStageCode, params);
    return urlParts;
  }

  private getNextTaskUrlParts(nextTaskCode, nextStageCode, params) {
    this.log.info(nextTaskCode, nextStageCode, params);
    if (nextTaskCode) {
      return internalUrls.onlineProcessPageTask(params.process, params.year, params.stage, nextTaskCode);
    } else if (nextStageCode) {
      return internalUrls.onlineProcessPageStage(params.process, params.year, nextStageCode);
    }
  }

  jumpToContent(jumpToElement: string): void {
    super.jumpToContent(jumpToElement);
  }

  toUCOnlineDashboard(): void {
    this.log.info('To UC Online Dashboard');
    window.location.assign(environment.totaraDashboardUrl);
  }

  skipVerification() {
    this.goToNextTask();
    this.ngxSmartModalService.close('verificationSkipWarningModal');
  }

  closeValidationWarningModal() {
    this.ngxSmartModalService.close('validationWarningModal');
  }

  pathToSectionSet(pathArr: string[]): string[] {
    this.log.info(pathArr);
    const sectionArr = new Set<string>();
    pathArr.map((path) => {
      sectionArr.add(PATH_TO_SECTION.uconlineSection[path] || '');
    });

    return Array.from(sectionArr);
  }

  retryCheckoutAgain() {
    // TODO: Need to have a real test on this
    this.enrolmentService
      .getIndependentEnrolment(
        this.applicationYear,
        this.onlineCourseService.onlineCourseCode,
        this.onlineCourseService.onlineCourseOccur,
        true,
      )
      .subscribe((independentEnrolment) => window.location.assign(independentEnrolment.checkoutUrl));
  }
}
