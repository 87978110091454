import { Component, OnInit, ViewChild, AfterViewInit, Directive, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { get } from 'lodash-es';
import { merge as observableMerge, zip, Subscription, ReplaySubject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { MessageBannerComponent } from '@shared/components/atoms/message-banner/message-banner.component';
import { ACTION_LABELS } from '@shared/constants/actions.constants';
import { UCError } from '@shared/models/errors';
import { UCProcess } from '@shared/models/process';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { UCErrorCodes } from '@shared/services/data-service';
import { DocumentService } from '@shared/services/document/document.service';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { ProcessService } from '@shared/services/process/process.service';
import { IProcessRouteParams } from '@shared/services/resolvers/process-resolver/process-resolver.service';
import { UserService } from '@shared/services/user/user.service';
import { UserActivityService } from '@shared/services/user-activity/user-activity.service';

export interface IProcessPage {
  process: UCProcess;
  stage: Stage;
  task: Task;
  currentTask: AbstractBaseTask;
  errorBanner: MessageBannerComponent;
  strings: object;
  errorMessage: string;
  stageComplete: boolean;
  isFirstTask: boolean;
  taskIsUpdating: boolean;
  newProcessName: string;
  applicationYear: string;
  stageNumber: number;
  showTaskError: VoidFunction;
  goToNextTask: VoidFunction;
  previousTask: VoidFunction;
  cancelTask: VoidFunction;
  triggerTaskUpdate: VoidFunction;
}

export interface INextTaskOptions {
  autoResolve: boolean;
}

@Directive()
export abstract class BaseProcessPage extends UnsubscribeOnDestroy implements IProcessPage {
  @ViewChild('currentTask') currentTask!: AbstractBaseTask;
  @ViewChild('errorBanner') errorBanner!: MessageBannerComponent;

  protected log: Logger;
  public process: UCProcess;
  public task: Task;
  public stage: Stage;
  public newProcessName: string;
  public applicantSub: Subscription;
  public strings;
  public errorMessage: string;
  public isFirstTask: boolean;
  public taskIsUpdating: boolean;
  public applicationYear: string;
  public stageNumber: number;

  abstract get stageComplete(): boolean;

  abstract get isImpersonating(): boolean;

  abstract get disableNavigation(): boolean;

  abstract get firstIncompleteStageNumber(): number;

  abstract showTaskError(): void;

  abstract goToNextTask(): void;

  abstract previousTask(): void;

  abstract cancelTask(): void;

  abstract triggerTaskUpdate(): void;

  jumpToContent(jumpToElement: string): void {
    document.getElementById(jumpToElement).focus();
  }

  showErrorBanner() {
    if (this.errorBanner) {
      this.errorBanner.hide = false;
    }
  }

  hideErrorBanner() {
    if (this.errorBanner) {
      this.errorBanner.hide = true;
    }
  }

  public getTaskAndStageIndex(params: IProcessRouteParams): { taskIndex: number; stageIndex: number } {
    let taskIndex;
    let stageIndex;

    this.process.stages.forEach((stage, stageI) => {
      if (stage.code === params.stage) {
        stageIndex = stageI;
        taskIndex = this.getTaskIndex(stage, params.task);
      }
    });
    return { taskIndex, stageIndex };
  }

  public getTaskIndex(stage, taskCode): number {
    let taskIndex;

    stage.tasks.forEach((t, taskI) => {
      if (t.code === taskCode) {
        taskIndex = taskI;
      }
    });
    return taskIndex;
  }

  public firstVisibleTaskIndex() {
    let firstTaskIndexFound = 0;
    let foundIndex = false;
    this.stage.tasks.forEach((task, index) => {
      if (!foundIndex && (!task.autoResolve || (task.autoResolve && task.percentComplete < 100))) {
        foundIndex = true;
        firstTaskIndexFound = index;
      }
    });

    return firstTaskIndexFound;
  }
}

@Component({
  selector: 'uc-process-page',
  templateUrl: './process-page.component.html',
  styleUrls: ['./process-page.component.scss'],
})
export class ProcessPageComponent extends BaseProcessPage implements OnInit, AfterViewInit, OnDestroy {
  public process: UCProcess;
  public task: Task;
  public stage: Stage;
  public params: IProcessRouteParams;

  public strings = strings.components.template.processPage;
  public errorStrings = strings.errors;
  public errorMessage = '';
  public isFirstTask = false;
  public taskIsUpdating = false;
  public newProcessName: string;
  public applicationYear: string;
  public stageNumber: number;
  public impersonating = false;
  public firstIncompleteStage: Stage;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public flashMessageService: FlashMessageService,
    private applicationService: ApplicationService,
    private coeService: ChangeOfEnrolmentService,
    private applicantService: ApplicantService,
    private documentService: DocumentService,
    private enrolmentService: EnrolmentService,
    private processService: ProcessService,
    loggingService: LoggingService,
    private titleService: Title,
    private userActivityService: UserActivityService,
    private userService: UserService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get stageComplete() {
    // the api will calculate when the stage is complete and return the actionLabel as 'save_and_submit'
    return this.task && this.task.actionLabel === ACTION_LABELS.SAVE_AND_SUBMIT;
  }

  get saveAndExit() {
    // the api will calculate whether the user should be redirected to the dashboard without showing a modal
    const exitLabels = [ACTION_LABELS.SAVE_AND_EXIT_ALWAYS, ACTION_LABELS.SAVE_AND_EXIT];
    return !!exitLabels.find((label) => get(this, 'task.actionLabel') === label);
  }

  get actionLabel() {
    const actionLabel = get(this, 'task.actionLabel');
    const stageNum = get(this.getTaskAndStageIndex(this.params), 'stageIndex') + 1;
    const processCode = get(this, 'process.code');
    const stageCount = get(this.process, 'stages.length');
    return this.strings.actionLabel(actionLabel, stageNum, processCode, stageCount);
  }

  get isImpersonating() {
    return this.impersonating;
  }

  get isFirstIncompleteStage() {
    return this.stage.code === get(this, 'firstIncompleteStage.code');
  }

  get disableNavigation() {
    return this.impersonating && !this.isFirstIncompleteStage;
  }

  get firstIncompleteStageNumber() {
    return this.process.stages.findIndex((el) => el.code === get(this, 'firstIncompleteStage.code')) + 1;
  }

  get flashMessage() {
    const { stageIndex } = this.getTaskAndStageIndex(this.params);
    const { isInternational } = this.process;
    if (this.stageComplete) {
      return this.strings.flashMessage(stageIndex, this.process.code, isInternational);
    }
  }

  ngAfterViewInit() {
    if (!this.errorMessage) {
      this.hideErrorBanner();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit() {
    zip(this.route.data, this.route.params)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([data, params]) => {
        this.updateDataOnResolverEmit(data, params);
      });

    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.setPageTitle();
        this.hideErrorBanner();
      });

    observableMerge(
      this.applicantService.applicantError,
      this.applicationService.applicationError,
      this.coeService.coeError,
      this.documentService.documentError,
      this.enrolmentService.error,
    )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((error) => {
        this.showTaskErrors(error);
      });

    this.userService.userDetail
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((detail) => (this.impersonating = detail && !!detail.impersonated));
  }

  private showTaskErrors(error) {
    if (error.code.match(/missing/) || error.code === UCErrorCodes.E404) {
      return;
    }

    if (error.code === UCErrorCodes.E409) {
      this.errorMessage = this.errorStrings.fourOhNine;
      this.router.navigate(internalUrls.dashboard);
    }

    if (error.code === UCErrorCodes.E422) {
      this.errorMessage =
        get(strings, get(error, 'data[0].detail')) ||
        get(error, 'data[0].original_msg') ||
        this.errorStrings.fourTwentyTwo;
      if (this.currentTask.updateFormValidity) {
        this.currentTask.updateFormValidity(error);
      } else {
        this.log.error(
          new Error('Error: task components must extend AbstractBaseTask and implement updateFormValidity'),
        );
      }
    } else {
      this.errorMessage = get(strings, error.code);
    }
    this.showErrorBanner();
  }

  private updateDataOnResolverEmit(data, params) {
    this.process = get(data, 'processContext.process');
    this.applicationYear = get(data, 'processContext.application.academicYear.code');
    this.stage = this.process.getStage(params.stage);
    this.task = this.process.getTask(params.stage, params.task);
    this.stageNumber = this.process.stages.findIndex((st) => st.code === params.stage) + 1;
    this.params = params;

    this.firstIncompleteStage = this.process.firstIncompleteStage;

    const { taskIndex } = this.getTaskAndStageIndex(this.params);
    this.isFirstTask = taskIndex === this.firstVisibleTaskIndex();
  }

  private setPageTitle() {
    const taskTitle = get(this, 'task.title');
    if (taskTitle) {
      this.titleService.setTitle(`${taskTitle} | Application to Enrol | UC`);
    }
  }

  /**
   * Only provide errors to this event output handler if you want the user to see the banner.
   * This must be called at any and all points at which `this.currentTask.update`
   * completes or returns without calling `this.next.emit()`.
   *
   * @param error
   */
  showTaskError(error?: UCError) {
    this.taskIsUpdating = false;
    if (error) {
      this.errorMessage = get(strings, error.code);
      this.showErrorBanner();
    }
  }

  async triggerTaskUpdate() {
    // if stopTaskSubmit then stay on current task. Handle stop validation is task's update() method
    if (get(this, 'currentTask.stopTaskSubmit')) {
      this.currentTask.update();
      return;
    }

    if (!get(this, 'currentTask.dontTriggerTaskIsUpdating')) {
      this.taskIsUpdating = true;
    }

    await this.userActivityService.addTask(this.params);

    if (get(this, 'currentTask.update')) {
      this.currentTask.update();
    } else {
      this.goToNextTask();
    }
  }

  cancelTask() {
    this.router.navigate(internalUrls.manageMyStudy, { queryParams: { year: this.params.year } });
  }

  previousTask() {
    return this.router.navigate(this.getPreviousUrl(this.params));
  }

  completeStage() {
    if (this.task.stop) {
      this.goToDashboard(this.flashMessage).then(() => (this.taskIsUpdating = false));
    } else {
      this.router.navigate(this.getNextUrl(this.params)).then(() => (this.taskIsUpdating = false));
    }
  }

  goToNextTask(options: INextTaskOptions = { autoResolve: false }) {
    this.hideErrorBanner();
    if (this.stageComplete) {
      return this.completeStage();
    }

    if (this.saveAndExit && !options.autoResolve) {
      return this.goToDashboard(this.flashMessage).then(() => (this.taskIsUpdating = false));
    }

    this.processService
      .evaluateProcess(this.process.code, this.params.year)
      .pipe(filter((process) => !!process))
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe({
        next: (process) => {
          this.process = process;
          this.stage = process.getStage(this.params.stage);
          this.task = process.getTask(this.params.stage, this.params.task);

          if (!this.stage || !this.task) {
            this.log.error('missing stage/task data:', this.params);
            return this.goToDashboard();
          }

          return this.router.navigate(this.getNextUrl(this.params));
        },
        complete: () => {
          this.taskIsUpdating = false;
        },
      });
  }

  public goToDashboard(message?: string): Promise<boolean> {
    if (message) {
      this.flashMessageService.pushSuccess(message, { countdown: 10 });
    }
    return this.router.navigate(internalUrls.dashboard);
  }

  private getPreviousUrl(params: IProcessRouteParams) {
    return this.getNextUrl(params, false);
  }

  private getNextUrl(params: IProcessRouteParams, forward = true) {
    const { taskIndex, stageIndex } = this.getTaskAndStageIndex(params);
    const nextStageIndex = forward ? stageIndex + 1 : stageIndex - 1;
    const nextTaskIndex = forward ? taskIndex + 1 : taskIndex - 1;

    const nextStageCode = get(this, `process.stages[${nextStageIndex}].code`);
    const nextTaskCode = get(this, `stage.tasks[${nextTaskIndex}].code`);

    // If there is a nextTaskCode, it means we aren't done the current stage yet, so ignore nextStageCode
    let urlParts;
    if (nextTaskCode) {
      urlParts = internalUrls.processPageTask(params.process, params.year, params.stage, nextTaskCode);
    } else if (nextStageCode) {
      urlParts = internalUrls.processPageStage(params.process, params.year, nextStageCode);
    } else {
      urlParts = internalUrls.dashboard;
    }

    return urlParts;
  }

  jumpToContent(jumpToElement: string): void {
    super.jumpToContent(jumpToElement);
  }
}
