import { Injectable } from '@angular/core';
import { remove, updateWith, constant } from 'lodash-es';
import { throwError, of, Subject, Observable, BehaviorSubject, from } from 'rxjs';
import { map, switchMap, filter, tap, catchError } from 'rxjs/operators';

import { environment } from '@environment';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { snakeifyKeys } from '@shared/helpers/serialization';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { Condition } from '@shared/models/condition';
import { ValidationMessage } from '@shared/models/enrolment';
import { UCError } from '@shared/models/errors';
import { UCProcess, UCProcessSummary, UCProcessStageSummary } from '@shared/models/process';
import { ReferenceData } from '@shared/models/reference-data';
import { mockData as mockApplicationResponse } from '@shared/services/application/application.data.mock';
import { ApplicationService } from '@shared/services/application/application.service';
import {
  CacheManagementService,
  ICacheOptions,
  CacheObjects,
} from '@shared/services/cache-management/cache-management.service';
import { mockData as mockCOEResponse } from '@shared/services/change-of-enrolment/change-of-enrolment.data.mock';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { DataService, DSHttpError, UCErrorCodes } from '@shared/services/data-service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { UserService } from '@shared/services/user/user.service';
import { WindowService } from '@shared/services/window/window.service';

import { expectedProcessResponse } from './online-process.mock.data';
import {
  CourseType,
  OnlineCourseService,
} from '../../../../../online-ui/src/app/services/online-course/online-course.service';

export interface IApplicationProcess {
  application: Application | ChangeOfEnrolment;
  process: UCProcess;
}

interface NotifyDocumentBody {
  data: {
    action: string;
    academicYear: string | null;
    condition?: { [key: string]: unknown };
  };
}

export abstract class AbstractProcessService {
  protected cache = {};
  protected readonly error$ = new Subject<UCError>();
  protected readonly serviceUrl: string = `${environment.apiRoot}/process`;
  // This is a fully hydrated UCProcess, which includes stage and task information
  // This process will have an associated Application
  public readonly process$ = new BehaviorSubject<UCProcess>(null);
  // These are "bare" processes with no stage or task information
  // This is a list of all available processes, and may or may not have an associated Application
  public readonly allProcesses$ = new BehaviorSubject<UCProcess[]>([]);

  get errors() {
    return this.error$.asObservable();
  }

  get currentProcess(): Observable<UCProcess> {
    return this.process$.asObservable();
  }

  get allProcesses(): Observable<UCProcess[]> {
    return this.allProcesses$.asObservable().pipe(
      map((value) => {
        // Since this is a mutable array, copy it so we can't mutate the cached value
        return (value || []).concat();
      }),
    );
  }

  protected clearCache(): void {
    this.cache = {};
    this.process$.next(null);
  }

  protected static keyForProcessCache(year: string, processCode: string): string {
    return `${year}-${processCode}`;
  }

  protected addCachedProcess(year: string, process: UCProcess): void {
    if (process && process.code) {
      this.cache[AbstractProcessService.keyForProcessCache(year, process.code)] = process;
    }
  }

  protected getCachedProcess(year: string, processCode: string): UCProcess {
    return this.cache[AbstractProcessService.keyForProcessCache(year, processCode)];
  }

  protected deleteCachedProcess(year: string, processCode: string) {
    delete this.cache[AbstractProcessService.keyForProcessCache(year, processCode)];
  }

  protected abstract getCachedApplication(year: string, processCode: string): Application | ChangeOfEnrolment;

  abstract evaluateProcess(processCode: string, applicationYear: string): Observable<UCProcess>;

  abstract getProcessSummary(processCode: string, applicationYear: string): Observable<UCProcessSummary>;

  /**
   * Fetch all available processes
   */
  abstract getProcesses(): Observable<UCProcess[]>;

  abstract hydrate(): Observable<boolean>;

  abstract fetchCurrentApplicationProcess(
    processCode: string,
    applicationYear: string,
    forceEvaluation: boolean,
  ): Observable<IApplicationProcess>;

  abstract fetchCOEProcess(
    processCode: string,
    applicationYear: string,
    forceEvaluation: boolean,
  ): Observable<IApplicationProcess>;

  abstract cancelProcess(processCode: string, applicationYear: string);
}

@Injectable()
export class OnlineProcessService extends AbstractProcessService {
  private log: Logger;

  constructor(
    private dataService: DataService,
    loggingService: LoggingService,
    private userService: UserService,
    private applicationService: ApplicationService,
    private windowService: WindowService,
    cacheService: CacheManagementService,
    private coeService: ChangeOfEnrolmentService,
    private onlineCourseService: OnlineCourseService,
  ) {
    super();
    this.log = loggingService.createLogger(this);

    const shouldClearProcess = (options: ICacheOptions): boolean => {
      return [CacheObjects.ALL, CacheObjects.PROCESS].indexOf(options.target) >= 0;
    };

    cacheService.shouldClearCache.pipe(filter(shouldClearProcess)).subscribe(() => this.clearCache());

    this.errorHandler();
  }

  get errors() {
    return this.error$.asObservable();
  }

  get currentProcess(): Observable<UCProcess> {
    return this.process$.asObservable();
  }

  private errorHandler() {
    this.error$
      .pipe(
        filter((err: DSHttpError) => err.status === 417),
        switchMap((error) => {
          this.log.error('Duplicate user detected from process service, refreshing token and reloading.', error);
          return this.userService.refreshTokenBeforeNavigate();
        }),
      )
      .subscribe(() => {
        this.windowService.nativeWindow.location.reload();
      });
  }

  evaluateProcess(processCode: string, applicationYear: string): Observable<UCProcess> {
    const url = `${this.serviceUrl}/${processCode}/${applicationYear}/evaluation`;
    return this.dataService
      .post(url, null, {
        error$: this.error$,
        success$: this.process$,
        errorCodes: {
          '417': UCErrorCodes.E417,
        },
        deserialize: (payload) => {
          return this.onlineEvaluateProcessDeserialize(payload, processCode, applicationYear);
        },
      })
      .pipe(
        tap((process: UCProcess) => {
          this.addCachedProcess(applicationYear, process);
        }),
      );
  }

  private onlineEvaluateProcessDeserialize = (payload, processCode, applicationYear) => {
    const manipulatedProcess = UCProcess.deserialize(
      this.manipulateProcessPayload(processCode, payload),
      applicationYear,
    );
    manipulatedProcess.stages = manipulatedProcess.stages.map((stage) => {
      stage.path = stage.path?.replace('/apply', '/online-apply');
      stage.tasks = stage.tasks.map((task) => {
        task.path = task.path?.replace('/apply', '/online-apply');
        return task;
      });
      return stage;
    });

    this.process$.next(manipulatedProcess);
    return manipulatedProcess;
  };

  // To make backend fixture data more easier to maintain, do filter and modify on front-end
  private manipulateProcessPayload(processCode, payload) {
    if (processCode !== 'new_student') {
      console.log(this.onlineCourseService.onlineCourseType);
      this.changesOnIsSC(payload);
      this.changesOnIsMC(payload);
    }
    return payload;
  }

  private changesOnIsMC(payload) {
    if (this.onlineCourseService.onlineCourseType === CourseType.MC) {
      if (this.onlineCourseService.onlineCourseIsFirstTimeToThisCourseType) {
        updateWith(payload, 'stages[0].title', constant('Apply for course'));
        updateWith(payload, 'stages[0].tasks[1].title', constant('Your details'));
        updateWith(payload, 'stages[3].tasks[0].title', constant('Processing enrolment'));
        remove(payload.stages[0].tasks, {
          code: 'uconline-study-location',
        });
      } else {
        updateWith(payload, 'stages[0].title', constant('Review your profile'));
        updateWith(payload, 'stages[0].tasks[1].title', constant('Review Details'));
        updateWith(payload, 'stages[2].tasks[0].title', constant('Processing enrolment'));
        remove(payload.stages, {
          code: 'uconline-additional-details',
        });
      }
    }
  }

  private changesOnIsSC(payload) {
    if (this.onlineCourseService.onlineCourseType === CourseType.SC) {
      updateWith(payload, 'stages[2].tasks[0].title', constant('Processing enrolment'));
      if (this.isSCReview()) {
        updateWith(payload, 'stages[0].title', constant('Apply for course'));
        updateWith(payload, 'stages[0].tasks[1].title', constant('Your details'));
        remove(payload.stages[0].tasks, {
          code: 'uconline-study-location',
        });
      } else {
        updateWith(payload, 'stages[0].title', constant('Review your profile'));
        updateWith(payload, 'stages[0].tasks[1].title', constant('Review Details'));
        remove(payload.stages, {
          code: 'uconline-additional-details',
        });
      }
    }
  }

  private isSCReview() {
    return (
      this.onlineCourseService.onlineCourseIsFirstTimeToThisCourseType &&
      !this.onlineCourseService.onlineCourseIsMCEnrolledBefore
    );
  }

  protected getCachedApplication(year: string, processCode: string): Application | ChangeOfEnrolment {
    const app = this.getApplicationOrCOE(processCode);
    const relevantApplication = app && app.academicYear.code === year;
    return relevantApplication ? app : null;
  }

  private getApplicationOrCOE(processCode: string) {
    let app: Application | ChangeOfEnrolment;
    if (processCode === PROCESS_NAMES.COE) {
      app = this.coeService.changeOfEnrolment as ChangeOfEnrolment;
    } else {
      app = this.applicationService.currentApplication as Application;
    }
    return app;
  }

  validateApplicationEnrolment(year: string, enrolmentPriority: number): Observable<ValidationMessage> {
    const url = `${this.serviceUrl}/enrolment/validation/${year}/${enrolmentPriority}`;
    return this.dataService.post(
      url,
      {},
      {
        error$: this.error$,
        deserialize: ValidationMessage.deserialize,
      },
    );
  }

  submitEnrolment(year: string) {
    const url = `${this.serviceUrl}/enrolment/submit/${year}`;
    return this.dataService.post(
      url,
      {},
      {
        error$: this.error$,
      },
    );
  }

  /**
   * This method handles both the process and the application, because the two are 1:1 unique related,
   *  and if one is stale, then we must assume that the other is stale as well.
   *
   * This method is primarily intended to be used with the process resolver, where we need to make sure
   *  that the current application and the current process are aligned.
   *
   * Something something hard things in computer science something something naming things something something
   */
  fetchCurrentApplicationProcess(
    processCode: string,
    applicationYear: string,
    forceEvaluation: boolean,
  ): Observable<IApplicationProcess> {
    // TODO: add some tests for this --
    if (!forceEvaluation) {
      const cachedProcessApplication = this.useCachedProcessApplication(applicationYear, processCode);
      if (cachedProcessApplication !== undefined) {
        return cachedProcessApplication;
      }
    }

    let application: Application;
    return this.applicationService.getApplication(applicationYear, processCode).pipe(
      switchMap((a) => {
        application = a;
        return this.evaluateProcess(processCode, applicationYear);
      }),
      map((process: UCProcess): IApplicationProcess => ({ process, application })),
    );
  }

  private useCachedProcessApplication(applicationYear, processCode) {
    const cachedProcess = this.getCachedProcess(applicationYear, processCode);
    const cachedApplication = this.getCachedApplication(applicationYear, processCode) as Application;

    if (cachedProcess && cachedApplication) {
      return of({
        process: cachedProcess,
        application: cachedApplication,
      });
    } else {
      return undefined;
    }
  }

  fetchCOEProcess(
    processCode: string,
    applicationYear: string,
    forceEvaluation: boolean,
  ): Observable<IApplicationProcess> {
    if (!forceEvaluation) {
      const cachedProcessCOE = this.useCachedProcessCOE(applicationYear, processCode);
      if (cachedProcessCOE) {
        return cachedProcessCOE;
      }
    }

    let application: ChangeOfEnrolment;
    return this.coeService.getChangeOfEnrolment().pipe(
      switchMap((a) => {
        application = a;
        return this.evaluateProcess(processCode, applicationYear);
      }),
      map((process: UCProcess): IApplicationProcess => ({ process, application })),
    );
  }

  private useCachedProcessCOE(applicationYear, processCode) {
    const cachedProcess = this.getCachedProcess(applicationYear, processCode);
    const cachedCOE = this.getCachedApplication(applicationYear, processCode) as ChangeOfEnrolment;

    if (cachedProcess && cachedCOE) {
      return of({
        process: cachedProcess,
        application: cachedCOE,
      });
    } else {
      return undefined;
    }
  }

  getProcessSummary(processCode: string, applicationYear: string): Observable<UCProcessSummary> {
    const url = `${this.serviceUrl}/${processCode}/${applicationYear}`;
    return this.dataService.fetch(url, {
      error$: this.error$,
      deserialize: UCProcessSummary.deserialize,
      errorCodes: {
        '417': UCErrorCodes.E417,
      },
    });
  }

  getProcesses(): Observable<UCProcess[]> {
    if (this.allProcesses$.value && this.allProcesses$.value.length) {
      return this.allProcesses;
    }

    const url = this.serviceUrl;
    return this.dataService.fetch(url, {
      error$: this.error$,
      success$: this.allProcesses$,
      deserialize: (json) => UCProcess.deserializeAll(json),
      errorCodes: {
        '417': UCErrorCodes.E417,
      },
    });
  }

  hydrate(): Observable<boolean> {
    const url = `${this.serviceUrl}/hydrate/`;
    return this.dataService
      .fetch(url, {
        error$: this.error$,
        deserialize: () => true,
        errorCodes: {
          '417': UCErrorCodes.E417,
          '500': 'errors.hydrateFailed',
        },
      })
      .pipe(
        catchError((err) => {
          // Always logout if hydrate fails, user data can be in weird state
          return from(this.userService.logout()).pipe(() => throwError(err));
        }),
      );
  }

  immediateHydrate(): Observable<boolean> {
    const url = `${this.serviceUrl}/hydrate/immediate`;
    return this.dataService.post(
      url,
      {},
      {
        error$: this.error$,
        deserialize: () => true,
      },
    );
  }

  syncProfile(): Observable<unknown> {
    const url = `${this.serviceUrl}/sync-profile/`;
    return this.dataService.post(
      url,
      {},
      {
        error$: this.error$,
        deserialize: () => true,
      },
    );
  }

  syncProfileForStaff(canonicalId: string): Observable<unknown> {
    const url = `${this.serviceUrl}/staff/${canonicalId}/sync-profile/`;
    return this.dataService.post(
      url,
      {},
      {
        error$: this.error$,
        deserialize: () => true,
      },
    );
  }

  syncBackgroundForStaff(canonicalId: string): Observable<unknown> {
    const url = `${this.serviceUrl}/staff/${canonicalId}/sync-background/`;
    return this.dataService.post(
      url,
      {},
      {
        error$: this.error$,
        deserialize: () => true,
      },
    );
  }

  notifyDocumentUpload(documentId: string, academicYear: string, relatedCondition?: Condition): Observable<unknown> {
    const url = `${this.serviceUrl}/upload-document/${documentId}/`;
    const body: NotifyDocumentBody = { data: { action: 'out_of_process', academicYear } };
    if (!!relatedCondition) {
      const condition = Condition.serialize(relatedCondition);
      body.data = { action: 'condition_upload', academicYear, condition };
    }
    body.data = snakeifyKeys(body.data);
    return this.dataService.post(url, body, {
      error$: this.error$,
      deserialize: () => true,
    });
  }

  submitStage(processName, year, stageCode): Observable<UCProcess> {
    const url = `${this.serviceUrl}/${processName}/${year}/evaluation/${stageCode}`;
    return this.dataService.post(url, {}, this.submitStageOptions(processName, year)).pipe(
      tap((process: UCProcess) => {
        this.addCachedProcess(year, process);
      }),
    );
  }

  submitStageOptions(processName, year) {
    return {
      error$: this.error$,
      success$: this.process$,
      errorCodes: {
        '417': UCErrorCodes.E417,
      },
      deserialize: (payload) => {
        const manipulatedProcess = UCProcess.deserialize(this.manipulateProcessPayload(processName, payload), year);
        this.process$.next(manipulatedProcess);
        return manipulatedProcess;
      },
    };
  }

  getStageSummary(processName, year): Observable<UCProcessStageSummary> {
    const url = `${this.serviceUrl}/${processName}/${year}`;
    return this.dataService.fetch(url, {
      error$: this.error$,
      deserialize: UCProcessStageSummary.deserialize,
      errorCodes: {
        '417': UCErrorCodes.E417,
      },
    });
  }

  validateCoe(): Observable<ValidationMessage> {
    const url = `${this.serviceUrl}/enrolment/coe/validate`;
    return this.dataService.post(
      url,
      {},
      {
        error$: this.error$,
        deserialize: ValidationMessage.deserialize,
      },
    );
  }

  refreshCoe(): Observable<unknown> {
    const url = `${this.serviceUrl}/enrolment/coe/refresh`;
    return this.dataService.post(
      url,
      {},
      {
        error$: this.error$,
      },
    );
  }

  createChangeOfEnrolment(academicYear: string, coe: ChangeOfEnrolment): Observable<ChangeOfEnrolment> {
    const url = `${this.serviceUrl}/enrolment/coe/${academicYear}`;
    const changeOfEnrolment = ChangeOfEnrolment.serialize(coe);
    return this.dataService.post(url, changeOfEnrolment, {
      deserialize: ChangeOfEnrolment.deserialize,
      error$: this.error$,
    });
  }

  withdrawApplicationEnrolment(year: string, priority: number, internalReference: string): Observable<unknown> {
    const url = `${this.serviceUrl}/enrolment/withdraw/${year}/${priority}/${internalReference}`;
    return this.dataService.del(url, {
      error$: this.error$,
    });
  }

  createChangeOfApplication(academicYear: string): Observable<unknown> {
    const url = `${this.serviceUrl}/application/${academicYear}/coa`;
    return this.dataService.post(
      url,
      {},
      {
        error$: this.error$,
      },
    );
  }

  updateExemptionReason(academicYear: string, internalReference: string): Observable<unknown> {
    const url = `${this.serviceUrl}/enrolment/exemption-reason/${academicYear}/${internalReference}`;
    return this.dataService.post(
      url,
      {},
      {
        error$: this.error$,
      },
    );
  }

  cancelProcess(processName: string, academicYear: string) {
    let url = `${this.serviceUrl}/application/${academicYear}/${processName}`;
    if (processName === PROCESS_NAMES.COE) {
      url = `${this.serviceUrl}/enrolment/coe/${academicYear}`;
    }
    return this.dataService.del(url, {
      error$: this.error$,
    });
  }

  getCitizenshipCategory(): Observable<ReferenceData> {
    const url = `${this.serviceUrl}/functions/citizenship-category`;
    return this.dataService.post(url, null, { error$: this.error$ });
  }

  isInternational(): Observable<boolean> {
    // See also process.ts#isInternational and application-to-enrol.component.ts#isInternational
    // Haven't yet deprecated the above as other business / UI logic may depend on these sligtly different calculations
    return this.getCitizenshipCategory().pipe(map((r) => r.code === 'OTHER'));
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export class MockOnlineProcessService extends AbstractProcessService {
  public mockData: UCProcess[];
  public deleteableProcesses = ['new_student'];
  public applicationService = {
    currentApplication: Application.deserialize(mockApplicationResponse()),
    getApplication: (a, p) => of(Application.deserialize(mockApplicationResponse())),
  };
  public coeService = {
    coe: ChangeOfEnrolment.deserialize(mockCOEResponse()),
    getChangeOfEnrolment: () => of(ChangeOfEnrolment.deserialize(mockCOEResponse())),
  };

  public hydrateResponse = true;

  constructor(mock?) {
    super();
    this.mockData = !!mock ? mock.processes || mock : expectedProcessResponse.processes;
  }

  // eslint-disable-next-line class-methods-use-this
  validateApplicationEnrolment(year: string, enrolmentPriority: number): Observable<ValidationMessage> {
    const mockValidationMessages = {
      validation: {
        enrolment_error: [
          {
            message: `Prerequisite requirement for "PHIL229 - Philosophy of Religion: Rationality, Science, and the God
              Hypothesis set to Not Met", failed due to the following errors:\nPrerequisite "PRQ: PHIL229 GROUP
              - PHIL229 Prerequisite Group" was not found`,
          },
        ],
        international_efts_warning: [
          {
            message: `\'ATE/2018/1 Wazowkski, Michael - 12345678 - Certificate of Proficiency applicant\' is not a full-time
              programme of study and may not satisfy the requirements of your Study Permit.`,
          },
        ],
      },
    };

    return of(ValidationMessage.deserialize(mockValidationMessages));
  }

  // eslint-disable-next-line class-methods-use-this
  submitEnrolment(year: string) {
    if (!year) {
      return throwError({ code: 'errors.invalidForm', data: 'No year specified' });
    }
    return of({});
  }

  getProcesses(): Observable<UCProcess[]> {
    return of(
      this.mockData.map((p) => {
        const clone = { ...p };
        delete clone.stages;
        return UCProcess.deserialize(clone);
      }),
    );
  }

  /**
   * Will fetch process - or create one if it doesn't exist
   */
  evaluateProcess(processCode: string, applicationYear: string): Observable<UCProcess> {
    const rawProcess = this.mockData.find((d) => d.code === processCode);
    const p = rawProcess ? UCProcess.deserialize(rawProcess, applicationYear) : null;
    this.process$.next(p);
    this.addCachedProcess(applicationYear, p);
    return of(p);
  }

  // eslint-disable-next-line class-methods-use-this
  syncProfile(): Observable<boolean> {
    return of(true);
  }

  // eslint-disable-next-line class-methods-use-this
  syncProfileForStaff(canonicalId: string): Observable<boolean> {
    return of(true);
  }

  // eslint-disable-next-line class-methods-use-this
  syncBackgroundForStaff(canonicalId: string): Observable<boolean> {
    return of(true);
  }

  // eslint-disable-next-line class-methods-use-this
  notifyDocumentUpload(docId: string, academicYear: string, relatedCondition?: Condition): Observable<boolean> {
    return of(true);
  }

  protected getCachedApplication(year: string, processCode: string): Application {
    return this.hasRelevantApplication(year, processCode) ? this.applicationService.currentApplication : null;
  }

  private hasRelevantApplication(year, processCode): boolean {
    const app = this.applicationService.currentApplication;
    return app && app.academicYear.code === year && app.processName.code === processCode;
  }

  fetchCurrentApplicationProcess(
    processCode: string,
    applicationYear: string,
    forceEvaluation: boolean,
  ): Observable<IApplicationProcess> {
    if (!forceEvaluation) {
      const cachedProcessApplication = this.useCachedProcessApplication(applicationYear, processCode);
      if (cachedProcessApplication) {
        return cachedProcessApplication;
      }
    }

    let application: Application;
    return this.applicationService.getApplication(applicationYear, processCode).pipe(
      switchMap((a) => {
        application = a;
        return this.evaluateProcess(processCode, applicationYear);
      }),
      map((process: UCProcess): IApplicationProcess => ({ process, application })),
    );
  }

  private useCachedProcessApplication(applicationYear, processCode) {
    const cachedProcess = this.getCachedProcess(applicationYear, processCode);
    const cachedApplication = this.getCachedApplication(applicationYear, processCode) as Application;

    if (cachedProcess && cachedApplication) {
      return of({
        process: cachedProcess,
        application: cachedApplication,
      });
    } else {
      return undefined;
    }
  }

  fetchCOEProcess(
    processCode: string,
    applicationYear: string,
    forceEvaluation: boolean,
  ): Observable<IApplicationProcess> {
    if (!forceEvaluation) {
      const cachedProcessCOE = this.useCachedProcessCOE(applicationYear, processCode);
      if (cachedProcessCOE) {
        return cachedProcessCOE;
      }
    }

    let application: ChangeOfEnrolment;
    return this.coeService.getChangeOfEnrolment().pipe(
      switchMap((a) => {
        application = a as unknown as ChangeOfEnrolment;
        return this.evaluateProcess(processCode, applicationYear);
      }),
      map((process: UCProcess): IApplicationProcess => ({ process, application })),
    );
  }

  private useCachedProcessCOE(applicationYear, processCode) {
    const cachedProcess = this.getCachedProcess(applicationYear, processCode);
    const cachedCOE = this.getCachedApplication(applicationYear, processCode) as unknown as ChangeOfEnrolment;

    if (cachedProcess && cachedCOE) {
      return of({
        process: cachedProcess,
        application: cachedCOE,
      });
    } else {
      return undefined;
    }
  }

  getProcessSummary(processCode: string, year: string): Observable<UCProcessSummary> {
    const response = { canDelete: false };
    response.canDelete = !!this.deleteableProcesses.find((p) => p === processCode);
    return of(response);
  }

  hydrate(): Observable<boolean> {
    return of(this.hydrateResponse);
  }

  immediateHydrate() {
    return of(this.hydrateResponse);
  }

  submitStage(processName, year, stageCode): Observable<UCProcess> {
    const rawProcess = this.mockData ? this.mockData.find((d) => d.code === processName) : null;
    const p = rawProcess ? UCProcess.deserialize(rawProcess, year) : null;
    this.process$.next(p);
    this.addCachedProcess(year, p);
    return of(p);
  }

  // eslint-disable-next-line class-methods-use-this
  getStageSummary(): Observable<UCProcessStageSummary> {
    return of({
      code: PROCESS_NAMES.NEW_STUDENT,
      completeStages: [],
      title: '',
      currentStage: 1,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  validateCoe(): Observable<ValidationMessage> {
    const mockValidationMessage = new ValidationMessage({
      validation: { withdraw_warnings: [{ message: 'Withdraw warning message.' }] },
    });
    return of(ValidationMessage.deserialize(mockValidationMessage));
  }

  // eslint-disable-next-line class-methods-use-this
  refreshCoe(): Observable<unknown> {
    return of({});
  }

  // eslint-disable-next-line class-methods-use-this
  withdrawApplicationEnrolment(year, priority, internalReference): Observable<unknown> {
    return of({});
  }

  // eslint-disable-next-line class-methods-use-this
  createChangeOfEnrolment(): Observable<unknown> {
    return of({});
  }

  // eslint-disable-next-line class-methods-use-this
  createChangeOfApplication(): Observable<unknown> {
    return of({});
  }

  // eslint-disable-next-line class-methods-use-this
  updateExemptionReason(): Observable<unknown> {
    return of();
  }

  // eslint-disable-next-line class-methods-use-this
  cancelProcess(year: string, processName: string) {
    return of({});
  }

  // eslint-disable-next-line class-methods-use-this
  getCitizenshipCategory(): Observable<ReferenceData> {
    return of({
      code: 'OTHER',
      description: 'International',
    });
  }

  isInternational(): Observable<boolean> {
    return this.getCitizenshipCategory().pipe(map((r) => r.code === 'OTHER'));
  }
}

export const processServiceProvider = {
  provide: OnlineProcessService,
  deps: [
    DataService,
    LoggingService,
    ApplicationService,
    UserService,
    WindowService,
    CacheManagementService,
    ChangeOfEnrolmentService,
    OnlineCourseService,
  ],
};
