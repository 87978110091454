<uc-form-template>
  <div class="content-container">
    <img
      src="assets/img/enrolment_success.svg"
      alt="enrolment-success"
      class="success-icon"
    />
    <div class="description-container">
      <h4>{{ strings.title }}</h4>
      <p>
        {{ strings.description[0] }}
        {{ strings.description[1] }}<span style="font-style: italic">{{ courseName }}</span>
      </p>
      <p [innerHTML]="strings.description[2]"></p>
      <p>
        {{ strings.description[3] }}
      </p>
    </div>
  </div>
</uc-form-template>
