import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import * as sentry from '@sentry/angular-ivy';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-modialog-11';
import { BootstrapModalModule } from 'ngx-modialog-11/plugins/bootstrap';
import { WidgetRegistry, SchemaFormModule } from 'ngx-schema-form';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { AppComponent } from '@app/app.component';
import { appRoutesModule } from '@app/app.routes';
import { OnlineOrganismsModule } from '@app/components/organisms/online-organisms.module';
import { TemplatesModule } from '@app/components/templates/templates.module';
import { OnlineCourseService } from '@app/services/online-course/online-course.service';
import { OnlineAdditionalDetailsComponent } from '@app/tasks/online-additional-details/online-additional-details.component';
import { OnlineLearnerDeclarationComponent } from '@app/tasks/online-learner-declaration/online-learner-declaration.component';
import { OnlineMicroCredentialDetailsComponent } from '@app/tasks/online-micro-credential-details/online-micro-credential-details.component';
import { OnlineResolveAccountComponent } from '@app/tasks/online-resolve-account/online-resolve-account.component';
import { OnlineShortCourseDetailsComponent } from '@app/tasks/online-shortcourse-details/online-shortcourse-details.component';
import { OnlineStudyHistoryComponent } from '@app/tasks/online-study-history/online-study-history.component';
import { BaseApplicantModule } from '@shared/applicant/base-applicant.module';
import { QualificationModule } from '@shared/applicant/qualification/qualification.module';
import { UcWidgetRegistry } from '@shared/classes/widget-registry';
import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { ProcessHydrateOverlayComponent } from '@shared/components/atoms/process-hydrate-overlay/process-hydrate-overlay.component';
import { MoleculesModule } from '@shared/components/molecules/molecules.module';
import { OrganismsModule } from '@shared/components/organisms/organisms.module';
import { DashboardTemplateComponent } from '@shared/components/templates/dashboard-template/dashboard-template.component';
import { DocumentTemplateComponent } from '@shared/components/templates/document-template/document-template.component';
import { InformationRequiredComponent } from '@shared/components/templates/information-required-template/information-required-template.component';
import { ManageMyStudyTemplateComponent } from '@shared/components/templates/manage-my-study-template/manage-my-study-template.component';
import { OutageTemplateComponent } from '@shared/components/templates/outage-template/outage-template.component';
import { PageNotFoundTemplateComponent } from '@shared/components/templates/page-not-found-template/page-not-found-template.component';
import { SsoTemplateComponent } from '@shared/components/templates/sso-template/sso-template.component';
import { SharedTemplatesModule } from '@shared/components/templates/templates.module';
import { WidgetsModule } from '@shared/components/widgets/widgets.module';
import { fakeAngularFireAuth } from '@shared/mocks/angular-fire-mock.service';
import { fakeBackendProvider } from '@shared/mocks/xhrbackend-mock';
import { ApplicantService, applicantServiceProvider } from '@shared/services/applicant/applicant.service';
import { ApplicationService, applicationServiceProvider } from '@shared/services/application/application.service';
import { CacheManagementService } from '@shared/services/cache-management/cache-management.service';
import {
  changeOfEnrolmentServiceProvider,
  ChangeOfEnrolmentService,
} from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { conditionServiceProvider } from '@shared/services/condition/condition.service';
import { courseServiceProvider } from '@shared/services/course/course.service';
import { DataService } from '@shared/services/data-service';
import { DefaultLandingUrlService } from '@shared/services/default-landing-url/default-landing-url.service';
import { documentServiceProvider } from '@shared/services/document/document.service';
import { enrolmentServiceProvider } from '@shared/services/enrolment/enrolment.service';
import {
  EnrolmentSummaryService,
  enrolmentSummaryServiceProvider,
} from '@shared/services/enrolment-summary/enrolment-summary.service';
import { FileUploadService } from '@shared/services/file-upload/file-upload.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { FullEnrolmentService } from '@shared/services/full-enrolment/full-enrolment.service';
import { AuthOnloadGuard } from '@shared/services/guards/auth-onload.guard';
import { FeatureGuard } from '@shared/services/guards/feature.guard';
import { OutageGuard } from '@shared/services/guards/outage.guard';
import { StudentIdGuard } from '@shared/services/guards/student-id.guard';
import { UnsavedDataGuard } from '@shared/services/guards/unsaved-data.guard';
import { UserAuthenticatedGuardService } from '@shared/services/guards/user-authenticated-guard.service';
import { IPLocationService } from '@shared/services/iplocation/iplocation.service';
import { jadeHydrateProvider } from '@shared/services/jade-hydrate/jade-hydrate.service';
import { LoggingService } from '@shared/services/logging/logging.service';
import { ModalCoordinatorService } from '@shared/services/modal/modal-coordinator.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { notificationServiceProvider } from '@shared/services/notification/notification.service';
import { PaymentService } from '@shared/services/payment/payment.service';
import { PlacesService, placesServiceProvider } from '@shared/services/places.service';
import { processServiceProvider } from '@shared/services/process/process.service';
import { qualificationServiceProvider } from '@shared/services/qualification/qualification.service';
import { referenceDataServiceProvider } from '@shared/services/reference-data/reference-data.service';
import { SanctionsService, sanctionsServiceProvider } from '@shared/services/sanctions/sanctions.service';
import { sentryServiceFactory, sentryErrorProvider } from '@shared/services/sentry.provider';
import { UserService } from '@shared/services/user/user.service';
import { CookieUserActivityStrategyService } from '@shared/services/user-activity/cookie-user-activity-strategy.service';
import { FirebaseUserActivityStrategyService } from '@shared/services/user-activity/firebase-user-activity-strategy.service';
import { LocalStorageUserActivityStrategyService } from '@shared/services/user-activity/localstorage-user-activity-strategy.service';
import { mockBackendUserActivityProvider } from '@shared/services/user-activity/user-activity.service';
import { WindowService } from '@shared/services/window/window.service';
import { UcMaterialModule } from '@shared/uc-material/uc-material.module';

import { OnlineAtomsModule } from './components/atoms/online-atoms.module';
import { OnlineMoleculesModule } from './components/molecules/online-molecules.module';
import { MyucSearchFiltersComponent } from './components/organisms/myuc-search-filters/myuc-search-filters.component';
import { AuthActionComponent } from './components/templates/auth-action/auth-action.component';
import { LoginTemplateComponent } from './components/templates/login-template/login-template.component';
import { MyucApplicantSplashPageComponent } from './components/templates/myuc-applicant-splash-page/myuc-applicant-splash-page.component';
import { MyucCoursePlannerComponent } from './components/templates/myuc-course-planner/myuc-course-planner.component';
import { MyucManageMyStudyTemplateComponent } from './components/templates/myuc-manage-my-study-template/myuc-manage-my-study-template.component';
import { MyucPreProcessPageComponent } from './components/templates/myuc-pre-process-page/myuc-pre-process-page.component';
import { MyucProcessPageComponent } from './components/templates/myuc-process-page/myuc-process-page.component';
import { OnlineAccountSettingsComponent } from './components/templates/online-account-settings/online-account-settings.component';
import { OnlineDashboardTemplateComponent } from './components/templates/online-dashboard-template/online-dashboard-template.component';
import { OnlinePreProcessPageComponent } from './components/templates/online-pre-process-page/online-pre-process-page.component';
import { OnlineProcessPageComponent } from './components/templates/online-process-page/online-process-page.component';
import { OnlineProfileTemplateComponent } from './components/templates/online-profile-template/online-profile-template.component';
import { OnlineApplicantModuleAccessGuard } from './services/guards/online-applicant-module-access.guard';
import { OnlineAuthOnloadGuard } from './services/guards/online-auth-onload.guard';
import { OnlineUserAuthenticatedGuard } from './services/guards/online-user-authenticated.guard';
import { IndependentCourseEnrolmentService } from './services/independent-course-enrolment/independent-course-enrolment.service';
import { OnlineDefaultLandingUrlService } from './services/online-default-landing-url/online-default-landing-url.service';
import { OnlineProcessService } from './services/online-process/online-process.service';
import { OnlineProcessResolver } from './services/resolvers/online-process-resolver/online-process-resolver.service';
import { MyucFutureYearApplyComponent } from './tasks/myuc-future-year-apply/myuc-future-year-apply.component';
import { MyucQualificationComponent } from './tasks/myuc-qualification/myuc-qualification.component';
import { OnlineCompleteStageComponent } from './tasks/online-complete-stage/online-complete-stage.component';
import { OnlineEnrolmentSuccessComponent } from './tasks/online-enrolment-success/online-enrolment-success.component';
import { OnlineJudsCompleteStageComponent } from './tasks/online-juds-complete-stage/online-juds-complete-stage.component';
import { OnlinePaymentFailedComponent } from './tasks/online-payment-failed/online-payment-failed.component';
import { OnlinePaymentSuccessComponent } from './tasks/online-payment-success/online-payment-success.component';
import { OnlineResolveCheckoutSessionComponent } from './tasks/online-resolve-checkout-session/online-resolve-checkout-session.component';
import { OnlineResolveEnrolmentProvisioningComponent } from './tasks/online-resolve-enrolment-provisioning/online-resolve-enrolment-provisioning.component';
import { OnlineReviewMicroCredentialDetailsComponent } from './tasks/online-review-micro-credential-details/online-review-micro-credential-details.component';
import { OnlineReviewShortCourseDetailsComponent } from './tasks/online-review-shortcourse-details/online-review-shortcourse-details.component';
import { OnlineStudyLocationComponent } from './tasks/online-study-location/online-study-location.component';
import { OnlineVerificationComponent } from './tasks/online-verification/online-verification.component';

@NgModule({
  declarations: [
    AuthActionComponent,
    LoginTemplateComponent,
    OnlineAccountSettingsComponent,
    OnlineDashboardTemplateComponent,
    OnlinePreProcessPageComponent,
    OnlineProcessPageComponent,
    OnlineProfileTemplateComponent,
    AppComponent,
    DashboardTemplateComponent,
    DocumentTemplateComponent,
    InformationRequiredComponent,
    ManageMyStudyTemplateComponent,
    OutageTemplateComponent,
    PageNotFoundTemplateComponent,
    ProcessHydrateOverlayComponent,
    SsoTemplateComponent,
    OnlineShortCourseDetailsComponent,
    OnlineCompleteStageComponent,
    OnlineVerificationComponent,
    OnlineEnrolmentSuccessComponent,
    OnlinePaymentSuccessComponent,
    OnlinePaymentFailedComponent,
    OnlineMicroCredentialDetailsComponent,
    OnlineAdditionalDetailsComponent,
    OnlineResolveAccountComponent,
    OnlineJudsCompleteStageComponent,
    OnlineStudyHistoryComponent,
    OnlineLearnerDeclarationComponent,
    OnlineStudyLocationComponent,
    OnlineReviewMicroCredentialDetailsComponent,
    OnlineReviewShortCourseDetailsComponent,
    OnlineResolveCheckoutSessionComponent,
    OnlineResolveEnrolmentProvisioningComponent,
    MyucProcessPageComponent,
    MyucPreProcessPageComponent,
    MyucQualificationComponent,
    MyucManageMyStudyTemplateComponent,
    MyucApplicantSplashPageComponent,
    MyucFutureYearApplyComponent,
    MyucCoursePlannerComponent,
    MyucSearchFiltersComponent,
  ],
  imports: [
    OnlineAtomsModule,
    OnlineMoleculesModule,
    OnlineOrganismsModule,
    AngularFireAuthModule,
    AngularFireModule,
    AngularFirestoreModule,
    appRoutesModule,
    AtomsModule,
    BootstrapModalModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    MoleculesModule,
    NgxDatatableModule,
    NgxSmartModalModule.forRoot(),
    OrganismsModule,
    ReactiveFormsModule,
    SchemaFormModule.forRoot(),
    SharedTemplatesModule,
    TemplatesModule,
    UcMaterialModule,
    WidgetsModule,
    BaseApplicantModule,
    QualificationModule,
  ],
  providers: [
    OnlineAuthOnloadGuard,
    OnlineUserAuthenticatedGuard,
    OnlineApplicantModuleAccessGuard,
    ApplicantService,
    applicantServiceProvider,
    ApplicationService,
    applicationServiceProvider,
    AuthOnloadGuard,
    CacheManagementService,
    ChangeOfEnrolmentService,
    changeOfEnrolmentServiceProvider,
    conditionServiceProvider,
    CookieUserActivityStrategyService,
    courseServiceProvider,
    DataService,
    documentServiceProvider,
    enrolmentServiceProvider,
    EnrolmentSummaryService,
    enrolmentSummaryServiceProvider,
    fakeAngularFireAuth,
    fakeBackendProvider,
    FeatureGuard,
    FileUploadService,
    FirebaseUserActivityStrategyService,
    FlashMessageService,
    FormModelMapperService,
    FullEnrolmentService,
    IPLocationService,
    jadeHydrateProvider,
    LocalStorageUserActivityStrategyService,
    LoggingService,
    mockBackendUserActivityProvider,
    ModalCoordinatorService,
    ModalService,
    {
      provide: MSAL_INSTANCE,
      useFactory: UserService.msalInstanceFactory,
    },
    MsalBroadcastService,
    MsalService,
    notificationServiceProvider,
    OutageGuard,
    PlacesService,
    placesServiceProvider,
    processServiceProvider,
    qualificationServiceProvider,
    referenceDataServiceProvider,
    SanctionsService,
    sanctionsServiceProvider,
    sentryErrorProvider,
    StudentIdGuard,
    UnsavedDataGuard,
    UserAuthenticatedGuardService,
    UserService,
    WindowService,
    {
      provide: WidgetRegistry,
      useClass: UcWidgetRegistry,
    },
    {
      provide: sentry.TraceService,
      deps: [Router],
      useValue: undefined,
    },
    {
      // For explanation of APP_INITIALIZER, see: https://stackoverflow.com/a/44731279/8093350
      provide: APP_INITIALIZER,
      useFactory: sentryServiceFactory,
      deps: [sentry.TraceService],
      multi: true,
    },
    OnlineProcessResolver,
    OnlineCourseService,
    IndependentCourseEnrolmentService,
    OnlineProcessService,
    {
      provide: DefaultLandingUrlService,
      useClass: OnlineDefaultLandingUrlService,
    },
    PaymentService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
